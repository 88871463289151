<template>
  <div
    class="w-full rouded bg-white p-4 rounded shadow hover:shadow-xl"
    :class="selected ? 'border-l-8 border-blue-400 shadow-xl' : ''"
    @click="$emit('click')"
  >
    <div
      class="-mt-4 -mx-4 hover:bg-gray-200 text-center rounded-top cursor-move"
    >
      <fa :icon="['far', 'grip-lines']" />
    </div>
    <div v-if="selected">
      <div class="flex">
        <sqr-input-text
          label="Question"
          class="w-2/3 mr-4"
          :value="question.name"
          @change="fieldSet('name', $event)"
        />
        <sqr-input-select
          label="Type"
          class="w-1/3"
          :value="question.type"
          @selected="fieldSet('type', $event)"
          :options="types"
        />
      </div>
      <div class="pt-2" v-if="question.type">
        <component
          :is="question.type + '-edit'"
          :question="question"
          @change="$emit('change', $event)"
        />
      </div>
      <div class="flex justify-between pt-2">
        <div class="py-1">
          <sqr-input-text
            v-if="idEdit"
            :value="question.id"
            @change="fieldIdSet($event)"
          />
        </div>
        <div class="flex justify-end">
          <div class="py-1 flex items-center">
            <sqr-toggle
              :value="question.required"
              @change="fieldSet('required', $event)"
            />
            <div class="px-1 text-sm">required</div>
          </div>
          <sqr-button
            icon="clone"
            color="white"
            class="mr-1"
            @click="$emit('clone')"
          />
          <sqr-button icon="trash" color="white" @click="confirmRemove()" />
        </div>
      </div>
    </div>
    <div v-else-if="question.type">
      <component :is="question.type + '-view'" :question="question" />
    </div>
  </div>
</template>

<script>
import { fromPairs } from 'ramda';
import SqrButton from '@/sqrd-ui/SqrButton';
import SqrInputText from '@/sqrd-ui/SqrInputText';
import SqrInputSelect from '@/sqrd-ui/SqrInputSelect';
import SqrToggle from '@/sqrd-ui/SqrToggle';

const comps = [
  'text',
  'textarea',
  'number',
  'radio',
  'checkbox',
  'select',
  'file',
  //
  'header',
  'name',
  'email',
  'address',
  //
  'product',
  //
  'scale',
  'grid-radio',
  'grid-checkbox',
  'date',
  'time',
];
const viewComps = comps.map(name => [
  `${name}-view`,
  () => import(`./parts/${name}/View`),
]);
const editComps = comps.map(name => [
  `${name}-edit`,
  () => import(`./parts/${name}/Edit`),
]);

export default {
  name: 'Question',
  components: {
    SqrButton,
    SqrInputText,
    SqrInputSelect,
    SqrToggle,
    ...fromPairs(viewComps),
    ...fromPairs(editComps),
  },
  props: {
    question: { type: Object, required: true },
    selected: { type: Boolean },
    idEdit: Boolean,
  },
  computed: {
    types() {
      return [
        { id: 'text', label: 'Short Answer' },
        { id: 'textarea', label: 'Paragraph' },
        { id: 'number', label: 'Number' },
        { id: 'radio', label: 'Multiple choice' },
        { id: 'checkbox', label: 'Checkboxes' },
        { id: 'select', label: 'Drop-down' },
        { id: 'file', label: 'File upload' },

        { id: 'header', label: 'Header' },
        { id: 'name', label: 'Name' },
        { id: 'address', label: 'Address' },
        { id: 'email', label: 'email' },

        { id: 'product', label: 'Product (stripe)' },

        { id: 'scale', label: 'Linear scale' },
        { id: 'grid-radio', label: 'Multiple choice grid' },
        { id: 'grid-checkbox', label: 'Tick box grid' },
        { id: 'date', label: 'Date' },
        { id: 'time', label: 'Time' },
      ];
    },
  },
  methods: {
    fieldIdSet(value) {
      const id = value.replace(/^[^a-zA-Z_$]|[^\w$]/g, '_');
      this.$emit('change', { ...this.question, id });
    },
    fieldSet(field, value) {
      this.$emit('change', { ...this.question, [field]: value });
    },
    confirmRemove() {
      if (confirm(`Remove question [${this.question.name}]?`)) {
        this.$emit('remove');
      }
    },
  },
};
</script>
