<template>
  <div v-if="model" class="max-w-2xl mx-auto">
    <questions-edit
      class="mt-8"
      :questions="model.questions"
      id-edit
      @change="fieldSet('questions', $event)"
    />
  </div>
</template>

<script>
import QuestionsEdit from '@/sqrd-forms/QuestionsEdit';
import { mapState, mapActions } from 'vuex';
export default {
  name: 'InquiryModelQuestions',
  components: { QuestionsEdit },
  computed: {
    ...mapState('inquiryModel', { model: 'doc' }),
    ...mapState('inquiryModel', ['path'])
  },
  methods: {
    ...mapActions('updater', ['update']),
    fieldSet(field, value) {
      const path = this.path;
      return this.update({ path, doc: { [field]: value } });
    }
  },
  metaInfo() {
    const name = this.model?.name ?? '';
    const title = `${name} - questions - modèle de demande d'offres`;
    return { title };
  }
};
</script>
