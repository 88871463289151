<template>
  <div>
    <div class="flex justify-between mb-1" v-if="label">
      <label class="block text-sm font-medium leading-5 text-gray-700">{{
        label
      }}</label>
      <span class="text-sm leading-5 text-gray-500" v-if="required">
        {{ $t ? $t('sqrd_ui_required') : 'Required' }}
      </span>
    </div>
    <select
      class="block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
      :class="inputClass"
      v-model="option"
      v-bind="$attrs"
    >
      <option value="" disabled>Please select…</option>
      <option v-for="opt in options" :key="opt.id" :value="opt.id">{{
        opt.label
      }}</option>
    </select>
    <slot name="invalid" v-if="v && v.$error && !v.required">
      <p class="mt-1 text-xs text-red-600">{{ label }} is required.</p>
    </slot>
  </div>
</template>

<style src="./SqrInput.postcss" lang="postcss" scoped></style>

<style lang="postcss" scoped>
.form-select:disabled {
  @apply text-gray-700 bg-gray-100 cursor-not-allowed;
}
</style>

<script>
import SqrInput from './SqrInput';
export default {
  name: 'SqrInputSelect',
  mixins: [SqrInput],
  model: { input: 'value', event: 'selected' },
  props: {
    name: String,
    value: { type: [String, Number] },
    options: Array
  },
  computed: {
    option: {
      get() {
        return this.value ?? '';
      },
      set(value) {
        this.$emit('selected', value);
        this.$emit('change', value);
      }
    }
  }
};
</script>
