<template>
  <div @click.self="selectedId = null">
    <draggable group="questions" v-model="list">
      <question-edit
        class="mb-6"
        v-for="(question, index) in questions"
        :key="question.id"
        :question="question"
        :selected="selectedId === question.id"
        :id-edit="idEdit"
        @change="questionSet(index, $event)"
        @clone="questionClone(index)"
        @remove="remove(index)"
        @click="selectedId = question.id"
      />
      <div
        class="sticky bottom-0 bg-white p-2 border-t border-l border-r shadow-2xl rounded-t"
      >
        <sqr-button
          icon="plus"
          label="Add a question"
          color="primary"
          @click="add()"
        />
      </div>
    </draggable>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import { nanoid } from 'nanoid';
import { clone } from 'ramda';
import SqrButton from '@/sqrd-ui/SqrButton';
import QuestionEdit from './QuestionEdit';
export default {
  name: 'QuestionsEdit',
  components: { SqrButton, QuestionEdit, Draggable },
  props: {
    questions: { type: Array, default: () => [] },
    idEdit: Boolean
  },
  computed: {
    list : {
      get() {
        return this.questions;
      },
      set(questions) {
        this.$emit('change', questions);
      }
    }
  },
  data() {
    return {
      selectedId: null
    };
  },
  methods: {
    add() {
      const before = this.questions || [];
      const id = nanoid(5);
      const question = { id, type: 'text', label: '' };
      this.$emit('change', [...before, question]);
    },
    questionSet(index, question) {
      const questions = clone(this.questions);
      questions.splice(index, 1, question);
      this.$emit('change', questions);
    },
    questionClone(index) {
      const questions = clone(this.questions);
      const question = clone(questions[index]);
      question.id = nanoid(5);
      questions.splice(index, 0, question);
      this.$emit('change', questions);
      this.selectedId = question.id;
    },
    remove(index) {
      const questions = clone(this.questions);
      questions.splice(index, 1);
      this.$emit('change', questions);
    }
  }
};
</script>
